import React from "react";
import UserNavBarComponent from '../components/UserNavBarComponent';
import UserSignupComponent from '../components/UserSignupComponent';

export const UserSignupPage = ()=>{
    return (
        <>
        <UserNavBarComponent/>
        <UserSignupComponent/>
        </>
    );
}