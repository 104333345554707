import React from 'react'
import UserNavBarComponent from '../components/UserNavBarComponent'
import UserbookingComponent from '../components/UserbookingComponent'
import UserFooterComponent from '../components/UserFooterComponent'
function UserBookingPage ()  {
  return (
    <>
    <UserNavBarComponent/>
    <UserbookingComponent/>
    <UserFooterComponent/>
    </>
  )
}

export default UserBookingPage