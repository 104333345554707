import React from 'react'
import UserNavBarComponent from '../components/UserNavBarComponent'
import UserLoginComponent from '../components/UserLoginComponent'


export const UserLoginPage = () => {
  return (
   <>
   <UserNavBarComponent/>
   <UserLoginComponent/>
   </>
  )
}

export default UserLoginPage