import React from 'react'
import UserbookingComponent from '../components/UserbookingComponent'
import UserOrderStatusComponent from '../components/UserOrderStatusComponent'
import UserFooterComponent from '../components/UserFooterComponent'
import UserNavBarComponent from '../components/UserNavBarComponent'

const UserOrderStatusPage = () => {
  return (
    <>
   
    <UserOrderStatusComponent/>
   
    </>
  )
}

export default UserOrderStatusPage